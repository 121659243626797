import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "utils/csrf_token";

export default class extends Controller {
  static targets = ['button']
  static values = { url: String }

  connect() {
    this.toggleBlankState();
  }

  markAsDelivered(event) {
    event.preventDefault();
    this.replaceLine('PATCH');
    this.toggleBlankState();
  }

  markAsDone(event) {
    event.preventDefault();
    this.addSpinner();
    this.replaceLine('PATCH');
    this.toggleBlankState();
  }

  undo(event) {
    event.preventDefault();
    this.replaceLine('DELETE');
    this.toggleBlankState();
  }

  async replaceLine(method) {
    const response = await fetch(this.urlValue, { method: method, headers: { 'X-CSRF-Token': csrfToken() }});
    const contentHtml = await response.text();
    const line = $(this.element);
    line.fadeOut(400, () => { line.replaceWith($(contentHtml)); });
  }

  addSpinner() {
    const button = this.buttonTarget;
    if (!button) return;
    button.innerHTML = '<div class="text-center"><i class="fad fa-spinner text-gray font-size-3xl rotating"></i><div>';
  }

  showBlankState() {
    const emptyState = document.querySelector("div[data-element=empty-list]") || document.querySelector("#empty-list");
    if (!emptyState) return;
    emptyState.classList.add('d-flex');
    emptyState.classList.remove('d-none');
  }

  hideBlankState() {
    const emptyState = document.querySelector("div[data-element=empty-list]") || document.querySelector("#empty-list");
    if (!emptyState) return;
    emptyState.classList.add('d-none')
    emptyState.classList.remove('d-flex')
  }

  toggleBlankState() {
    const unfinishedActions = document.querySelectorAll('[data-controller=desktop--hr--tabs--action-lists] [done=false]');

    if (unfinishedActions.length >= 1) {
      this.hideBlankState();
    } else {
      this.showBlankState();
    }
  }
}
