// To reuse this modal controller you need to do the following things:
// Already done for the #modal-sidebar
// - Put a data-controller attribute on the modal element (it will be retrieved using this.element)
// - Put a data-target='content' on the div encapsulating the content of the modal (we will replace this content)

// To-do for each element invoking modal-sidebar
// - Put a data-target="#modal-sidebar"
// - Put a data-toggle="modal"
// - Put a data-url='<route_helper>'

// In the rails controller :
// Just render the action without the layout
// respond_to do |format|
//    format.html { render layout: false }
// end

// You can also replace the content of the modal by using the method replaceContent which should be place under the declaration
// of this js controller. In that case you just put a div with those data attributes :
// data-url='<route_helper>'
// data-action="click->desktop--hr--modal#replaceContent"
// data-method="patch" to specify a method (default is get)

// In this controller we are listening to the boostrap event 'show.bs.modal' happening on this.element
// which is the data-controller element (in our case #modal-sidebar).
// If we detect that the modal is opening we make a request using the data-url present on the element
// that triggered the opening event.
// Finally, we parse the response as text() and replace our contentTarget by our parsed response.

import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import { recoverSelectedTab } from "pages/desktop/hr/sidebar_tabs";
import { reloadAdministrativeTabProgress } from 'pages/desktop/hr/administrative_progress';
import { connectScorm } from '../settings/templates/modal_controller';

export default class extends Controller {
  static targets = ['content']

  connect() {
    $(this.element).on('show.bs.modal', this.fetch.bind(this));
    $(this.element).on('hide.bs.modal', this.hideCallback);
  }

  replaceContent(event) {
    const url = event.currentTarget.dataset.url;
    const method = this.setMethod(event.currentTarget.dataset.method).toUpperCase();
    this.load(url, method);
  }

  load(url, method) {
    $.ajax({
      url: url,
      method: method,
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      dataType: 'html',
      success: (data_as_text) => {
        this.fill_content(data_as_text);
      },
      error: () => {
        // if user's session is expired the server respond with a 401
        // which means that the request failed. We handle the failure in
        // this function which reloads the page, therefore redirecting us
        // to the sign-in page
        window.location.reload();
      }
    });
  }

  fetch(event) {
    if (!event.relatedTarget) { return };
    this.addSpinner();

    const url = event.relatedTarget.dataset.url;
    if (url) {
      $.ajax({
        url: url,
        dataType: 'html',
        success: (data_as_text) => {
          this.fill_content(data_as_text);
        },
        error: () => {
          // if user's session is expired the server respond with a 401
          // which means that the request failed. We handle the failure in
          // this function which reloads the page, therefore redirecting us
          // to the sign-in page
          window.location.reload();
        }
      });
    }
  }

  addSpinner() {
    this.fill_content('<div class="text-center h-100 d-flex align-items-center justify-content-center"><i class="fad fa-spinner text-gray font-size-3xl rotating"></i><div>');
  }

  fill_content(data_as_text) {
    this.contentTarget.innerHTML = data_as_text;

    const preview_modal = document.getElementById('modal-sidebar-content');
    const scormIframe = document.querySelector('iframe[title="scorm"]');
    if (scormIframe) { connectScorm(preview_modal); };
  }

  setMethod(dataset) {
    return (dataset || 'get').toUpperCase();
  }

  hideCallback() {
    const selectedTab = recoverSelectedTab();
    if (selectedTab == 'administrative') { reloadAdministrativeTabProgress(); }
  }
}
