import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview"];

  change(event) {
    this.previewTargets.forEach((preview) => {
      if (preview.dataset.actorId === event.target.value) {
        preview.classList.remove("hidden");
      } else {
        preview.classList.add("hidden");
      }
    });
  }
}
