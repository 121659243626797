import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";

// controller used also when previewing quizzes (templates)
export default class extends Controller {
  static targets = ['panel']
  static values = { index: Number }

  start(event) {
    const url = event.currentTarget.dataset.url;
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ start: true })
    })

    this.next();
  }

  next() {
    this.indexValue += 1;
    this.updateHeader();
  }

  previous() {
    this.indexValue -= 1;
    this.updateHeader();
  }

  updateHeader() {
    const header = document.querySelector('.normal-header');
    const minimal_header = document.querySelector('.minimal-header');
    if ((this.indexValue >= 2) && header && minimal_header) {
      header.classList.add('d-none');
      minimal_header.classList.remove('d-none');
    }
  }

  finish(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;

    this.addSpinner();
    fetch(url, {
      method: "PATCH",
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(({content, quiz_id, success, quiz_line, is_track_offboarding }) => {
      this.fillContent(content);
      if (success) {
        if (document.getElementById('boardee-interface')) {
          // interface V2
          $('#modal-full').on('hidden.bs.modal', () => {
            let action = document.getElementById(`line-quiz-${quiz_id}`);
            updateBoardeeActions(action);
  
            $('#modal-full').off('hidden.bs.modal');
          });
        } else {
          // interface V1
          this.removeAction(quiz_id);
          // append done quiz to the end for the offboarding
          if (is_track_offboarding) {
            document.querySelector("#action-list div[data-desktop--offboardee--actions-target='list']").insertAdjacentHTML('beforeend', quiz_line);
          }
        }

        setTimeout(() => {
          document.querySelector('.quiz-result').removeAttribute('hidden');
        }, 200);
      }
    });
  }

  removeAction(quiz_id) {
    var quizAction = document.getElementById(`line-quiz-${quiz_id}`);
    if (quizAction) {
      var timelineSection = quizAction.closest('.timeline-section');
      if (timelineSection && timelineSection.querySelectorAll('.user-action').length === 1) {
        timelineSection.remove();
      }
      quizAction.remove();
    }
  }

  select(event) {
    const form = event.currentTarget.closest('form');
    fetch(form.action, { method: "PATCH", body: new FormData(form) })
  }

  addSpinner() {
    this.fillContent('<div class="d-flex justify-content-center align-items-center margin-top-130px"><i class="fad fa-spinner text-gray font-size-3xl rotating"></i><div>');
  }

  fillContent(data_as_text) {
    this.element.innerHTML = data_as_text;
  }

  indexValueChanged() {
    this.panelTargets.forEach((panel, index) => {
      // We need to add 1 to index because on the backend the the first question is index 1
      // and on javascript the first panel is index 0.
      panel.hidden = index + 1 != this.indexValue;
      document.querySelector('#trick').scrollIntoView(true);
      this.markQuestionAsViewed(panel, index + 1)
      if (!panel.hasAttribute('hidden') && panel.dataset.questionType == 'linked') {
        const controller = Stimulus.controllers.filter(el => el.identifier == 'desktop--onboardee--linked-questions')[0];
        controller.drawExistingAnswers(panel.id);
      }
    });
  }

  // used in the settings templates preview, the function is here as we use the panel
  refresh() {
    // We display the first question
    // 1 is the welcome page, so we show dirrectly the first question
    this.indexValue = 2;
    [...document.querySelectorAll('#results .d-none')].forEach(el => el.classList.remove('d-none'));
    [...document.querySelectorAll('input[type=checkbox]')].forEach(el => el.checked = false);

    const data = JSON.parse(document.getElementById('state').dataset.state);
    Object.entries(data).forEach(([_question_id, question_obj]) => {
      Object.entries(question_obj.answers).forEach(([_answer_id, answer]) => {
        answer.answer_twin_id = null;
        answer.selected = false
      });
    });
    resetLinkedQuestions();
    document.getElementById('state').dataset.state = JSON.stringify(data);
  }

  markQuestionAsViewed(panel, position) {
    let viewedAttribute = panel.dataset['desktop-Onboardee-RankingQuestionsViewedValue']
    if ((position == this.indexValue) && (viewedAttribute)) {
      if ((viewedAttribute == 'false')) {
        panel.dataset['desktop-Onboardee-RankingQuestionsViewedValue'] = 'true';
      }
    }
  }
}

const resetLinkedQuestions = () => {
  document.querySelectorAll('.first-bullet svg').forEach(svg => svg.remove());
  document.querySelectorAll('.first-bullet, .second-bullet').forEach(el => {
    el.classList.remove('selected')
    el.classList.add('invisible')
  });
  [...document.querySelectorAll('div[data-action="click->desktop--settings--templates--quiz-linked-question#unselectAnswers"]')].forEach(el => el.setAttribute('data-action', 'click->desktop--settings--templates--quiz-linked-question#selectAnswer'));
};
