import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["picker", "input"];

  connect() {
    $(this.pickerTarget).datetimepicker({ inline: true, format: "DD/MM/YYYY" });

    this._hide(this.pickerTarget);

    $(this.pickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;
      this.inputTarget.value = date.format("DD/MM/YYYY");
      this._hide(this.pickerTarget);
    });
  }

  show() {
    this._show(this.pickerTarget);
  }

  hide(event) {
    this._hide(this.pickerTarget);
  }

  hideIfOutside(event) {
    if (!this.element.contains(event.target)) {
      this._hide(this.pickerTarget);
    }
  }

  _show(datetimepickerTarget) {
    if (!datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("show");
      $(datetimepickerTarget)
        .find(".day.active")
        .on("click", () => {
          $(datetimepickerTarget).trigger("change.datetimepicker", {
            date: $(datetimepickerTarget).datetimepicker("date"),
          });
        });
    }
  }

  _hide(datetimepickerTarget) {
    if (datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("hide");
    }
  }
}
