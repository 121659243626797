const delay = 5000;
const fadeOut = 100;
const body = document.querySelector('body');
const content = document.createElement('div');

const clearPreviousFlash = () => {
  // avoid cumulating flashes div over itselves
  const flashDiv = document.getElementById('flash');
  if(flashDiv){ flashDiv.parentElement.parentElement.removeChild(flashDiv.parentElement) }
}

const setFlashContent = (text, type) => {
  content.innerHTML = `
    <div id="flash" class="d-flex w-100 justify-content-center">
      <div class="alert flash-${type} alertFadeOut" style="transition: ${fadeOut / 1000}s linear; opacity: 1;" role="alert">
        <i class="fad font-size-lg flash-icon ${iconClass(type)} padded-left-20px"></i>
        <div class="padded-lateral-20px text-overflow-two-lines line-height-20px"> ${text} </div>
        <i data-dismiss="alert" aria-label="close" class="fas fa-times text-gray padded-right-20px"></i>
      </div>
    </div>
  `;
}

const iconClass = (type) => {
  switch (type) {
    case 'alert':
      return 'fa-times-circle'
    case 'warning':
      return 'fa-exclamation-circle'
   default:
      return 'fa-check-circle'
  }
}

// Need this variables to store and clear previous timeouts
let flashFadeOutTimeout;
let flashRemoveTimeout;
const removeFlash = () => {
  window.clearTimeout(flashFadeOutTimeout);
  window.clearTimeout(flashRemoveTimeout);
  flashFadeOutTimeout = window.setTimeout(() => {
    let alertFadeOut = content.querySelector('.alertFadeOut')
    if (alertFadeOut) { alertFadeOut.style.opacity = 0 }
  }, delay);
  flashRemoveTimeout = window.setTimeout(() => { content.remove(); }, delay + fadeOut);
}

const flash = (text, type = 'notice') => {
  clearPreviousFlash();
  setFlashContent(text, type);
  body.appendChild(content);
  removeFlash();
}

export { flash };
