import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "utils/csrf_token";
import ProgressBar from "progressbar.js";

export default class extends Controller {
  static targets = ["progressBar"];

  static values = {
    submitUrl: String,
    nextUrl: String,
    token: String,
    count: Number,
  };

  tokenValueChanged(token) {
    if (!token) {
      return;
    }

    const bar = new ProgressBar.Circle(this.progressBarTarget, {
      color: "var(--primary-700)",
      trailColor: "var(--grey-200)",
      strokeWidth: 4,
      easing: "easeInOut",
      duration: 300,
      text: {
        style: {
          color: "var(--grey-800)",
          position: "absolute",
          left: "50%",
          top: "50%",
          padding: 0,
          margin: 0,
          transform: {
            prefix: true,
            value: "translate(-50%, -50%)",
          },
        },
        autoStyleContainer: true,
      },
      step: function (_, circle) {
        const value = Math.round(circle.value() * 100);
        circle.setText(value + " %");
      },
    });

    const self = this;

    App.cable.subscriptions.create(
      { channel: "AppliedTemplatesChannel", token },
      {
        received: (response) => {
          self.countValue += 1;

          if (response.total === 0) {
            bar.animate(1);
            location = self.nextUrlValue;
          } else if (self.countValue === response.total) {
            bar.animate(self.countValue / response.total);
            location = self.nextUrlValue;
          } else {
            bar.animate(self.countValue / response.total);
          }
        },
      },
    );

    const params = new URLSearchParams({ token });

    setTimeout(() => {
      fetch(`${this.submitUrlValue}?${params.toString()}`, {
        method: "PATCH",
        headers: { "x-csrf-token": csrfToken() },
      });
    }, 100);
  }
}
