import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = ["input", "template", "dropdown", "select", "none"];

  static values = {
    options: Array,
    pristine: { type: Boolean, default: true },
  };

  connect() {
    this.search = debounce(this.search.bind(this), 100);
  }

  search() {
    const value = this.inputTarget.value;
    const options = this.optionsValue
      .map((option) => {
        return { value: (option || "").toString() };
      })
      .filter((option) => {
        if (value) {
          return this._normalize(option.value).includes(this._normalize(value));
        } else {
          return true;
        }
      });

    const html = options
      .map((option) =>
        Object.entries(option).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  pick(event) {
    event.preventDefault();

    const optionElement = event.target.closest(".input--select__option");

    this.inputTarget.value = optionElement.dataset.value;
    this.dropdownTarget.hidden = true;
    this.pristineValue = true;
    this._workeloBlur(this.inputTarget);
  }

  touch() {
    this.pristineValue = false;
  }

  reset() {
    if (!this.pristineValue) {
      this.inputTarget.value = "";
    }
  }

  _debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  _normalize(string) {
    return (string || "")
      .toString()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }
}
