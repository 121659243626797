import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import { stringToHTML } from "utils/convert_html";

export default class extends Controller {
  static targets = ['element', 'editForm', 'content', 'approvalIcons' ]
  static values = {
    id: String,
    status: String,
    formStatus: String
  }

  locale = this.element.dataset.locale;

  connect() {
    useClickOutside(this);
    if(this.hasElementTarget && this.elementTarget.children.length === 0){
      this.wrappText();
      this.buildIcons();
    }
  }

  wrappText(){
    const translations = JSON.parse(document.getElementById("forms").parentElement.dataset.translations);

    if (this.elementTarget.dataset.paperworkType == 'file' && this.elementTarget.dataset.paperworkFilesCount == 1) {
      var template = `<div class="pointer files"><div class='label label-grey-300'><i class="fal fa-paperclip margin-right-10px" aria-hidden="true"></i>1 fichier</div></div>`
    } else if (this.elementTarget.dataset.paperworkType == 'file' && this.elementTarget.dataset.paperworkFilesCount > 1) {
      var template = `<div class="pointer files"><div class='label label-grey-300'><i class="fal fa-paperclip margin-right-10px" aria-hidden="true"></i>${this.elementTarget.dataset.paperworkFilesCount} fichiers</div></div>`
    } else if (this.elementTarget.dataset.paperworkType == 'file') {
      var template = "<div class='pointer files font-size-xxs '>" + translations["no_file"] + "</div>"
    } else {
      var template = "<div role='button' class='w-100  text-overflow' data-action='click->paperwork#edit' data-paperwork-target='content'>" + this.elementTarget.innerHTML + "</div>";
    }
    this.elementTarget.innerHTML = template;
  }

  edit(event){
    event.stopPropagation();
    this.removePopovers();

    this.approvalIconsTarget.classList.add('hidden');
    this.contentTarget.classList.add('hidden');

    $.ajax({
      url: `/${this.locale}/actor/analyse/paperworks/${this.idValue}/edit`,
      type: 'GET',
      dataType: 'json',
      error: (response) => {
        console.log(response);
      },
      success: (response) => {
        this.elementTarget.insertAdjacentHTML('beforeend', response.html);
        this.elementTarget.querySelector('[data-field-id]').removeAttribute('initial-hidden');
        this.elementTarget.querySelector('[data-field-id]').removeAttribute('hidden');
        setTimeout(() => {
          let selectInput = this.elementTarget.querySelector('select');
          let dateInput = this.elementTarget.querySelector('.datetimepicker-input');
          if (selectInput) {
            selectInput.selectize.focus();
          } else if (dateInput) {
            $(dateInput).datetimepicker("show");
          } else {
            let textInput = this.elementTarget.querySelector('input.form-control');
            textInput.focus();
            // Cursor on end of line
            textInput.setSelectionRange(textInput.value.length, textInput.value.length);
          }
        }, 200)
      }
    });
  }

  updateStatus(event){
    let status = this.defineStatus();
    event.preventDefault();
    event.stopPropagation();
    let currentController = this;
    this.update({
      paperwork: {
        status: status
      }
    }, function(response){
      currentController.statusValue = status

      trackPageLoad('Flag a field', {
        'mobility': response.mobility,
        'form_id': response.form_id,
        'paperwork_id': response.id,
        'flag_color': status,
        'url': window.location.href,
        'referrer': document.referrer,
        'event_time': new Date().toISOString()
      })
    })
  }

  update(params, callback) {
    this.removePopovers();
    $.ajax({
      url: `/${this.locale}/actor/analyse/paperworks/${this.idValue}`,
      type: 'PUT',
      dataType: 'json',
      data: params,
      error: (response) => {
        if (typeof callback === 'function') {
          callback(response)
        }
      },
      success: (response) => {
        if (typeof callback === 'function') {
          callback(response)
        }
      }
    });
  }

  removePopovers() {
    const popovers = document.querySelectorAll('.popover');
    popovers.forEach(popover => {
      popover.remove();
    });
  }

  buildIcons() {
    let templateStringifyHTML = document.getElementById('approval-icons-template').innerHTML;
    let templateHTML = stringToHTML(templateStringifyHTML)
    if (this.formStatusValue === "closed") {
      templateHTML.querySelector('.badge[status="refused"]')?.remove();
    }
    this.elementTarget.appendChild(templateHTML);
  }

  defineStatus() {
    switch(this.statusValue) {
    case 'pending':
      return 'checked'
    case 'checked':
      return this.element.querySelector("a[status='refused']") ? 'refused' : 'pending'
    default:
      return 'pending'
    }
  }
}
