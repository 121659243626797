import ProgressBar from 'progressbar.js';

// For manager dashboard
function progressBarManagerDashboard(selector, progress, background_color){
  // let selector = `#progress-score-${employee_id}`
  $(selector).html('');
  var line_bar = new ProgressBar.Line(selector, {
    color: '#c4d0fa',
    strokeWidth: 5,
    duration: 1500,
    easing: 'bounce',
    trailColor: background_color,
    trailWidth: 5,
    text: {
      value: progress + '%',
      className: 'text-grey-800 font-size-xxs'
    },
    from: { color: '#c4d0fa', width: 5 },
    to: { color: '#c4d0fa', width: 5 },
    step: function(state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
      circle.path.setAttribute('stroke-linecap', 'round');
    }
  });
  line_bar.animate(Math.max(0.01, progress / 100));
};

// For old show
function progressBarOnboardee(progress){
  var circle_bar = new ProgressBar.Circle('#onboarding-score', {
    color: '#FFFFFF',
    strokeWidth: 12,
    duration: 1500,
    easing: 'easeInOut',
    text: {
      value: progress + '%',
      className: 'onboarding-score-text'
    },
    from: { color: '#FFFFFF', width: 10 },
    to: { color: '#FFFFFF', width: 12 },
    step: function(state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
    }
  });
  circle_bar.animate(progress / 100);
};

// For new onboardee dashboard
function progressBarDashboard(progress){
  $('#onboarding-score').html('');
  var line_bar = new ProgressBar.Line('#onboarding-score', {
    color: '#2e5bec',
    strokeWidth: 12,
    duration: 1500,
    easing: 'bounce',
    trailColor: '#f2f2f2',
    trailWidth: 12,
    text: {
      value: progress + '%',
      className: 'onboarding-score-label d-none'
    },
    from: { color: '#2e5bec', width: 12 },
    to: { color: '#2e5bec', width: 12 },
    step: function(state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
      circle.path.setAttribute('stroke-linecap', 'round');
    }
  });
  line_bar.animate(Math.max(0.01, progress / 100));

  const url = document.getElementById("onboarding-score").dataset.endingUrl;
  if (progress === 100) {
    $.ajax({
      url: url,
      type: 'POST'
    });
  }
};

// For manager onboardee show
function progressBarOnboardeeShow(progress, device){
  var strokeWidth = (device === 'mobile') ? 8 : 3
  var strokeWidthBar = (device === 'mobile') ? strokeWidth - 1 : strokeWidth
  var bgColor = (device === 'mobile') ? '#FFF' : '#f2f2f2'
  var text = (device === 'mobile') ? { style: defineTextStyle(progress), autoStyleContainer: false } : ''

  var progressBar = document.getElementById('progress-bar-inline')
  var bar = new ProgressBar.Line(progressBar, {
    strokeWidth: strokeWidth,
    easing: 'easeInOut',
    duration: 1000,
    color: '#46CE92',
    trailColor: bgColor,
    trailWidth: strokeWidth,
    svgStyle: {width: '100%', borderRadius: '25px', height: '15px'},
    text: text,
    step: (state, bar) => {
      if (device === 'mobile') {
        bar.setText(Math.round(bar.value() * 100) + '%');
      }
      bar.path.setAttribute('stroke-width', strokeWidthBar);
    }
  });

  function defineTextStyle(progress) {
    if (progress < 0.2) {
      return {
        color: '#828282',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: `translate(-50%, -50%)`
      }
    } else {
      return {
        color: '#FFF',
        position: 'absolute',
        left: `${progress * 100}%`,
        top: '50%',
        transform: `translate(-110%, -50%)`
      }
    }
  }

  bar.animate(progress);
};

// This progress bar (line) is display with data-attributes
// It needs :
//   - data-progress
//   - data-stroke
//   - data-color
//   - data-background-color
//   - data-width
//   - data-trail (width of trail)
function progressLineWithText(selector){
  let element = document.querySelector(selector)
  let progress = element.dataset.progress

  element.innerHTML = ''

  var line_bar = new ProgressBar.Line(selector, {
    color: element.dataset.color,
    strokeWidth: element.dataset.stroke,
    duration: 1500,
    easing: 'bounce',
    trailColor: element.dataset.backgroundColor,
    trailWidth: element.dataset.trail,
    text: {
      value: progress + '%',
      className: `text-${textColorFor(progress)} font-size-xxs`
    },
    from: { color: element.dataset.color, width: element.dataset.stroke },
    to: { color: element.dataset.color, width: element.dataset.stroke },
    step: function(state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
      circle.path.setAttribute('stroke-linecap', 'round');
    }
  });

  line_bar.animate(Math.max(0.01, progress / 100));
};

function textColorFor(progress) {
  if (progress > 50) {
    return 'white'
  } else {
    return 'grey-800'
  }
}

function progressLineWithoutText(selector){
  let element = document.querySelector(selector)
  let progress = element.dataset.progress

  element.innerHTML = ''

  var line_bar = new ProgressBar.Line(selector, {
    color: element.dataset.color,
    strokeWidth: element.dataset.stroke,
    duration: 1500,
    easing: 'bounce',
    trailColor: element.dataset.backgroundColor,
    trailWidth: element.dataset.trail,
    from: { color: element.dataset.color, width: element.dataset.stroke },
    to: { color: element.dataset.color, width: element.dataset.stroke },
    step: function(state, circle) {
      circle.path.setAttribute('stroke', state.color);
      circle.path.setAttribute('stroke-width', state.width);
      circle.path.setAttribute('stroke-linecap', 'round');
    }
  });

  line_bar.animate(Math.max(0.1, progress / 100));
};

export { progressBarManagerDashboard, progressBarOnboardee, progressBarDashboard, progressBarOnboardeeShow, progressLineWithText, progressLineWithoutText };
