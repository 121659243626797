// This controller is used to list the templates where a resource is added
import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['quickView']
  static values = {
    url: String
  }

  spinnerHTML = "<div quick-view-spinner class='w-100 text-center'><i class='far fa-spinner-third rotating font-size-xl' style='height: 25px; width: 25px;'></i></div>"

  connect() {
    this.element.classList.add('position-relative');
    this.element.setAttribute('quick-view-status', 'closed');
    if (!this.hasQuickViewTarget) { this._insert() };
    useClickOutside(this);
  }

  clickOutside(event) {
    if (!this._clickOnQuickView(event)) {  this.close() }
  }

  toggle(event) {
    event.stopPropagation(); // prevent to click on the link if the element has one
    removePopover();
    this.quickViewTarget.getAttribute('opened') == 'false' ? this._open() : this.close();
  }

  close() {
    this.quickViewTarget.setAttribute('opened', 'false');
    this.element.setAttribute('quick-view-status', 'closed');
  }

  _open() {
    this.quickViewTarget.setAttribute('opened', 'true');
    this._load()
    setTimeout(removePopover, 300);
    this.element.setAttribute('quick-view-status', 'opened');
  }

  _load() {
    if (this.hasUrlValue) {
      this.quickViewTarget.innerHTML = this.spinnerHTML;
      fetch(this.urlValue, {
        headers: {
          'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        this._fill(data);
      }).catch(error => {
        console.log(error);
      })
    }
  }

  _fill(data) {
    this.element.querySelector("[quick-view-spinner]")?.remove();
    this.quickViewTarget.insertAdjacentHTML('beforeend', data);
  }

  _insert() {
    let quickViewHTML = `<div quick-view opened="false" data-utils--quick-views-target="quickView">${this.spinnerHTML}</div>`
    this.element.insertAdjacentHTML('beforeend', quickViewHTML);
  }

  _clickOnQuickView(event) {
    let _clickOnQuickView;

    // set _clickOnQuickView as true if the event target is or is contained in tabulator-row
    const path = event.path || (event.composedPath && event.composedPath())
    path.forEach(el => {
      if ((el.classList) && el.attributes.getNamedItem('quick-view')) {
        return _clickOnQuickView = true
      };
    });

    return _clickOnQuickView;
  }
}

const removePopover = () => { $('.popover').remove() }
