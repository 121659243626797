import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, date: String };

  dateKey = this.dateValue.replace("_d", "D");

  openModal() {
    if (this.element.dataset[this.dateKey] != this.element.value) {
      this._loadModalContent();
      $("#modal-sidebar").on("hide.bs.modal", this._setOldValue.bind(this));
    }
  }

  _loadModalContent() {
    const params = new URLSearchParams({
      date: this.dateValue,
      old_date_string: this.element.dataset[this.dateKey],
      new_date_string: this.element.value,
    });
    let url = `${this.urlValue}?${params}`;

    fetch(url, {
      headers: { Accept: "text/html" },
    })
      .then((response) => response.text())
      .then((data) => {
        document.getElementById("modal-sidebar-content").innerHTML = data;
        $("#modal-sidebar").modal("show");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  _setOldValue() {
    this.element.value = this.element.dataset[this.dateKey];
    $("#modal-sidebar").off("hide.bs.modal", this._setOldValue.bind(this));
  }
}
