import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = { text: Object, confirm: Object, cancel: Object, buttonType: String }

  buttonTypes = {
    normal: 'button-grey-800',
    danger: 'button-red',
    success: 'button-green',
  }

  popup = Swal.mixin({
    icon: 'warning',
    title: this.textValue.title_html,
    html: this.textValue.description_html,
    confirmButtonText: this.textValue.confirm,
    cancelButtonText: this.textValue.cancel,
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml: '<i class="fas fa-times-circle font-size-md"></i>',
    buttonsStyling: false,
    reverseButtons: true,
    customClass: {
      confirmButton: `button ${this.buttonTypes[this.buttonTypeValue]} m-2`,
      cancelButton: 'button button-white m-2'
    },
  })

  beforeBegin() {
    // Override in subclasses
    // Happen just after user clicked on the trigger
    // but before the opening of the popup
  }

  shouldFire() {
    // Override in subclasses
    // Useful to prevent the popup to open by returning false
    return true;
  }

  async confirm(event) {
    event.preventDefault();
    this.beforeBegin();

    if (this.shouldFire()) {
      const result = await this.popup.fire();

      if (result.isConfirmed) {
        this.confirmRequest(this.confirmValue)
      } else if (result.dismiss === 'cancel') {
        this.cancelRequest(this.cancelValue);
      }

      this.beforeEnd(result);
    } else {
      this.notFired();
    }

    this.afterEnd();
  }

  notFired() {
    // Override in subclasses
    // Happen if swal haven't fired
  }

  confirmRequest(confirmValue) {
    this.handleRequest(confirmValue)
  }

  cancelRequest(cancelValue) {
    this.handleRequest(cancelValue)
  }

  handleRequest({ remote, url, type, data}) {
    if(!url) return;

    if (remote) {
      $.ajax({ url, type, data, dataType: 'script' })
    } else {
      window.location.href = url;
    }
  }

  beforeEnd() {
    // Override in subclasses
    // Happen just after the ajax call, useful to do thing
    // based on the result returned by the server
    // Just pass result as a parameter and console log
    // it inside the function
  }

  afterEnd() {
    // Override in subclasses
    // Happen at the end
  }
}
