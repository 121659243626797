import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "startDatePicker",
    "endDatePicker",
    "startDateContainer",
    "endDateContainer",
    "startDate",
    "endDate",
    "select",
    "dropdown",
  ];

  static values = {
    pristineEndDate: { type: Boolean, default: true },
    pristineStartDate: { type: Boolean, default: true },
    pickingDate: { type: Boolean, default: false },
  };

  connect() {
    $(this.startDatePickerTarget).datetimepicker({
      inline: true,
      format: "DD/MM/YYYY",
    });

    $(this.endDatePickerTarget).datetimepicker({
      inline: true,
      format: "DD/MM/YYYY",
    });

    this._hide(this.startDatePickerTarget);
    this._hide(this.endDatePickerTarget);

    $(this.startDatePickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;
      this.startDateTarget.value = date.format("DD/MM/YYYY");
      this.pristineStartDateValue = true;
      this._hide(this.startDatePickerTarget);
    });

    $(this.endDatePickerTarget).on("change.datetimepicker", (event, data) => {
      const date = data?.date || event.date;
      this.endDateTarget.value = date.format("DD/MM/YYYY");
      this.pristineEndDateValue = true;
      this.pickingDateValue = false;
      this._hide(this.endDatePickerTarget);
      this._workeloBlur(this.endDateTarget);
    });
  }

  showStartDatePicker() {
    this._show(this.startDatePickerTarget);
  }

  hideStartDatePicker() {
    this._hide(this.startDatePickerTarget);
  }

  hideStartDatePickerIfOutside() {
    if (!this.startDateContainerTarget.contains(event.target)) {
      this._hide(this.startDatePickerTarget);
    }
  }

  showEndDatePicker() {
    this._show(this.endDatePickerTarget);
  }

  toggle(event) {
    event.preventDefault();
    this.dropdownTarget.hidden = !this.dropdownTarget.hidden;
  }

  show() {
    this.dropdownTarget.hidden = false;
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.endDateTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.endDateTarget);
    }

    if (
      this.pickingDateValue ||
      !this.endDateContainerTarget.contains(event.target)
    ) {
      this.pickingDateValue = false;
      this._hide(this.endDatePickerTarget);
      this._workeloBlur(this.endDateTarget);
    }
  }

  pickNone(event) {
    event.preventDefault();

    this.endDateTarget.value = "";
    this.dropdownTarget.hidden = true;
    this.pristineEndDateValue = true;
    this._workeloBlur(this.endDateTarget);
  }

  pickMonths(event) {
    event.preventDefault();

    const endDateElement = event.target.closest(".input--select__option");
    const months = parseInt(endDateElement.dataset.value, 10);
    const startDate = this.startDateTarget.value;

    if (startDate) {
      const endDate = moment(startDate, "DD/MM/YYYY")
        .add(months, "months")
        .format("DD/MM/YYYY");

      this.endDateTarget.value = endDate;
    }
    this.dropdownTarget.hidden = true;
    this.pristineEndDateValue = true;
    this._workeloBlur(this.endDateTarget);
  }

  pickDate(event) {
    event.preventDefault();

    this._show(this.endDatePickerTarget);
    this.dropdownTarget.hidden = true;
    setTimeout(() => {
      this.pickingDateValue = true;
    }, 400);
  }

  resetStartDate() {
    if (!this.pristineStartDateValue) {
      this.startDateTarget.value = "";
    }
  }

  reset() {
    if (!this.pristineEndDateValue) {
      this.endDateTarget.value = "";
    }
  }

  touchStartDate() {
    if (this._isDate(this.startDateTarget.value)) {
      this.pristineStartDateValue = true;
    } else {
      this.pristineStartDateValue = false;
    }
  }

  touch() {
    if (this._isDate(this.endDateTarget.value)) {
      this.pristineEndDateValue = true;
    } else {
      this.pristineEndDateValue = false;
    }
  }

  _isDate(string) {
    return /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{1,5}$/.test(string || "");
  }

  _show(datetimepickerTarget) {
    if (!datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("show");
      $(datetimepickerTarget)
        .find(".day.active")
        .on("click", () => {
          $(datetimepickerTarget).trigger("change.datetimepicker", {
            date: $(datetimepickerTarget).datetimepicker("date"),
          });
        });
    }
  }

  _hide(datetimepickerTarget) {
    if (datetimepickerTarget.querySelector(".datepicker")) {
      $(datetimepickerTarget).datetimepicker("hide");
    }
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }
}
