import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field']

  connect() {
    // define index depending on the ones already added
    this.index = +document.querySelectorAll('.template-rule').length - 1;
    const id = `template-rule-${this.index}`
    this.element.id = `template-rule-${this.index}`;
    this.element.querySelector('#wrapper_id').value = id;
    $(this.element.querySelector('#template_rule_template_id')).selectize();
  }

  addTemplateCriteria(event) {
    const id = event.currentTarget.dataset.id;
    let html = event.currentTarget.dataset.fields;
    html = html.replaceAll(id, new Date().getTime());
    this.element.querySelector('#template-criteria').insertAdjacentHTML('beforeend', html)
    const criteria = this.element.querySelector('#template-criteria').children;
    const bgColor = criteria.length % 2 == 0 ? 'bg-primary-300' : 'bg-primary-100'
    criteria[criteria.length - 1].classList.add(bgColor, 'border-radius-16px', 'padded-left-10px')
  }
}
