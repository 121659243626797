import { Controller } from "@hotwired/stimulus";
import { escapeHtml } from "utils/escapeHtml";
import { debounce } from "utils/debounce";

export default class extends Controller {
  static targets = [
    "unitOrganizationId",
    "input",
    "template",
    "dropdown",
    "select",
    "none",
  ];
  static values = {
    autocompleteUrl: String,
    autocompleteParams: Object,
    pristine: { type: Boolean, default: true },
  };

  connect() {
    this.search = debounce(this.search.bind(this), 100);
  }

  async search() {
    const query = this.pristineValue ? "" : this.inputTarget.value;
    const params = new URLSearchParams({
      ...this.autocompleteParamsValue,
      query,
    });
    const response = await fetch(
      `${this.autocompleteUrlValue}?${params.toString()}`,
    );
    const json = await response.json();

    const html = json.unit_organizations
      .map((actor) =>
        Object.entries(actor).reduce(
          (result, [key, value]) =>
            result.replaceAll(`{{${key}}}`, escapeHtml(value)),
          this.templateTarget.innerHTML,
        ),
      )
      .join("");

    this.dropdownTarget.innerHTML = html || this.noneTarget.innerHTML;
    this.dropdownTarget.hidden = false;
  }

  toggle(event) {
    event.preventDefault();

    if (this.dropdownTarget.hidden) {
      this.search();
    } else {
      this.dropdownTarget.hidden = true;
    }
  }

  hide(event) {
    if (!$(this.dropdownTarget).is(":hover")) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  hideIfOutside(event) {
    if (
      !this.selectTarget.contains(event.target) &&
      !this.dropdownTarget.hidden
    ) {
      this.dropdownTarget.hidden = true;
      this._workeloBlur(this.inputTarget);
    }
  }

  pick(event) {
    event.preventDefault();

    const option = event.target.closest(".input--select__option");

    this.inputTarget.value = option.dataset.name;
    this.unitOrganizationIdTarget.value = option.dataset.id;
    this.dropdownTarget.hidden = true;
    this.pristineValue = true;
    this._workeloBlur(this.inputTarget);
  }

  reset() {
    if (!this.pristineValue) {
      this.unitOrganizationIdTarget.value = "";
      this.inputTarget.value = "";
    }
  }

  touch() {
    this.pristineValue = false;
  }

  _workeloBlur(target) {
    $(target).trigger("workelo:blur", { target });
  }
}
