import { Controller } from "@hotwired/stimulus";

const ANIMATION_DURATION_MS = 300;

export default class extends Controller {
  static targets = ["summary", "details", "element", "show", "hide"];

  static values = {
    class: {
      type: String,
      default: "border-bottom-grey-300",
    },
    open: {
      type: Boolean,
      default: false,
    },
  };

  toggle(event) {
    event.preventDefault();
    this.openValue = !this.openValue;
  }

  openValueChanged(open) {
    const lastSummary = this.summaryTargets[this.summaryTargets.length - 1];

    if (lastSummary && this.hasElementTarget) {
      if (open) {
        lastSummary.classList.add(this.classValue);
      } else {
        lastSummary.classList.remove(this.classValue);
      }
    }

    if (open) {
      this.showTarget.hidden = true;
      this.hideTarget.hidden = false;
      $(this.detailsTarget).slideDown({
        duration: 300,
        easing: "swing",
      });
    } else {
      this.showTarget.hidden = false;
      this.hideTarget.hidden = true;
      $(this.detailsTarget).slideUp({
        duration: 300,
        easing: "swing",
      });
    }
  }
}
