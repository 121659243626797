import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';
import Swal from 'sweetalert2';
import Sortable from 'sortablejs';
import { formId,
         getStorage,
         setStorage,
         getStoredSectionFrom,
         buildSectionHtml } from 'packs/controllers/form_resources_controller';
import { scrollToElement } from "components/scrolling";

export default class extends Controller {
  static values = { text: Object }
  static targets = ['input', 'iterable', 'description']

  popup = Swal.mixin({
    icon: 'warning',
    title: this.textValue.title_html,
    html: this.textValue.description_html,
    confirmButtonText: this.textValue.confirm,
    cancelButtonText: this.textValue.cancel,
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml: '<i class="fas fa-times-circle font-size-md"></i>',
    buttonsStyling: false,
    customClass: {
      confirmButton: `button button-red m-2`,
      cancelButton: 'button button-white m-2'
    },
  })

  connect() {
    useClickOutside(this);
    this.sortable();
  }

  sortable() {
    const sections = document.querySelectorAll('.nested-sortable');
    [...sections].forEach(section => {
      new Sortable(section, {
        group: 'nested',
        store: null,
        animation: 40,
        fallbackOnBody: false,
        swapThreshold: 0.65,
        dragClass: 'group-dragging-item',
        filter: '.empty-line',
        handle: '.move-handle',
        fallbackTolerance: 30,
        onStart: function (event) {
          event.item.classList.add('group-dragging-item');
          window.addEventListener('drag', (e) => { verticalScrolling(e) });
        },
        onMove: (event, _originalEvent) => {
          const draggedType = event.dragged.dataset.type;
          const acceptedType = event.related.closest('[data-sortable-type]').dataset.sortableType;
          const result = draggedType == acceptedType;

          return result
        },
        onEnd: (event) => {
          const draggedType = event.item.dataset.type;
          draggedType == 'section' ? updateSectionPosition(event) : updatePaperworkPosition(event)
          event.item.classList.remove('group-dragging-item');
          window.removeEventListener('drag', (e) => { verticalScrolling(e) });
          if (event.item.dataset.type == 'paperwork') {
            let sectionId = event.to.closest('[data-type=section]').id
          }
        }
      })
    });
  }

  storeTitle() {
    const id = this.element.id;
    let storage = getStorage(formId(this.element));
    let storedSection = getStoredSectionFrom(storage, id);
    storedSection.title = this.inputTarget.innerHTML.replace(/&nbsp;/g, ' ').trim();

    setStorage(formId(this.element), storage);
  }

  storeDescription() {
    const id = this.element.id;
    let storage = getStorage(formId(this.element));
    let storedSection = getStoredSectionFrom(storage, id);
    storedSection.description = this.descriptionTarget.innerHTML.replace(/&nbsp;/g, ' ').trim();

    setStorage(formId(this.element), storage);
  }

  add() {
    const id = 'new_' + Date.now();
    const title = '';
    const description = '';
    const position = parseInt(this.element.dataset.position) + 1;
    let sectionHtml = buildSectionHtml(id, position, title, description);
    this.element.insertAdjacentHTML('afterend', sectionHtml);
    scrollToElement(`.section[id="${id}"]`, 80, '#page-content')

    const nextElementSibling = this.element.nextElementSibling.nextElementSibling;
    if (nextElementSibling) { this.propagatePosition(nextElementSibling, 1) };

    let storage = getStorage(formId(this.element));
    storage.sections.push({
      id: id,
      title: title,
      description: description,
      paperworks: [],
      position: position,
      iterable: false
    });
    setStorage(formId(this.element), storage);
  }

  propagatePosition(element, delta = 1) {
    let storage = getStorage(formId(this.element));
    while (element) {
      let position = element.dataset.position;
      let newPosition = parseInt(position) + delta;
      element.dataset.position = newPosition;
      let id = element.id;
      let storedSection = getStoredSectionFrom(storage, id);
      storedSection.position = newPosition;

      element = element.nextElementSibling;
      position++;
    }
    setStorage(formId(this.element), storage);
  }

  async delete(event) {
    event.preventDefault();
    if (this.lastElement()) {
      console.log('last element');
    } else {
      const result = await this.popup.fire();

      if (result.isConfirmed) {
        let id = this.element.id;
        let fId = formId(this.element);
        this.propagatePosition(this.element.nextElementSibling, -1);
        this.element.remove();

        let storage = getStorage(fId);
        storage.sections.forEach((storedSection, index) => {
          if (storedSection.id == id) { storage.sections.splice(index, 1); }
        });
        setStorage(fId, storage);
      } else if (result.dismiss === 'cancel') {
        // Do nothing
      }
    }
  }

  lastElement() {
    return this.element.parentNode.children.length == 1;
  }

  toggleAttribute(event) {
    let input = event.target;
    let attributeName = input.dataset.attributeTarget;
    this.element.dataset[attributeName] = input.checked;

    let sectionId = input.closest('[data-type="section"]').id;
    let storage = getStorage(formId(this.element));
    const storedSection = getStoredSectionFrom(storage, sectionId);
    storedSection[attributeName] = input.checked;
    setStorage(formId(this.element), storage);
  }

  stickDropdown() {
    this.element.setAttribute('dropdown-focus', '');
  }

  clickOutside() {
    this.hideDropdown();
    this.element.removeAttribute('dropdown-focus');
  }

  hideDropdown() {
    $(this.element.querySelector('[data-toggle=dropdown]')).dropdown('hide');
  }
}

const updateSectionPosition = (event) => {
  const sections = event.target.children
  const oldIndex = event.oldDraggableIndex;
  const newIndex = event.newDraggableIndex;
  const delta = newIndex - oldIndex;

  let startIndex = delta > 0 ? oldIndex : newIndex;
  let endIndex = delta > 0 ? newIndex : oldIndex;
  let index = startIndex;

  while (index !== endIndex) {
    let section = sections[index]
    section.dataset.position = index;
    index++;
  }
  sections[endIndex].dataset.position = endIndex;

  let storage = getStorage(formId(event.target));
  [...sections].forEach(section => {
    let storedSection = getStoredSectionFrom(storage, section.id)
    storedSection.position = section.dataset.position;
  })
  setStorage(formId(event.target), storage);
}

const updatePaperworkPosition = (event) => {
  let from = event.from;
  let to = event.to;

  repositionListOf(from);
  if (from != to) { repositionListOf(to) };
}

const repositionListOf = (element) => {
  let section = element.closest('.section');
  let paperworks = element.querySelectorAll('[data-type=paperwork]');
  paperworks.forEach((paperwork, index) => paperwork.dataset.position = index);

  let storage = getStorage(formId(element));
  let storedSection = getStoredSectionFrom(storage, section.id)

  storedSection.paperworks = [...paperworks].map(paperwork => paperworkToJSON(paperwork))
  setStorage(formId(element), storage);
}

const paperworkToJSON = (paperwork) => {
  return {
    id: paperwork.id,
    paperwork_resource_id: paperwork.dataset.paperworkResourceId,
    title: paperwork.querySelector('[data-paperwork-resources-target="input"]').innerText,
    position: parseInt(paperwork.dataset.position),
    new_data: paperwork.dataset.newData,
    hidden: paperwork.dataset.hidden,
    mandatory: paperwork.dataset.mandatory,
    icon: paperwork.querySelector('span.icon').innerHTML
  }
}

const verticalScrolling = (event) => {
  const listElement = document.querySelector("[data-form-resources-target=list][data-sortable-type=section");
  const cursorYPosition = event.clientY;
  const listTopPosition = listElement.getBoundingClientRect().top;
  const listBottomPosition = listElement.getBoundingClientRect().bottom;
  const windowHeight = window.innerHeight;
  const marge = 100;

  if (cursorYPosition < marge && listTopPosition < 90) {
    document.getElementById('page-content').scrollBy(0, -1);
  }
  if (cursorYPosition > (windowHeight - marge) && listBottomPosition > windowHeight - 30) {
    document.getElementById('page-content').scrollBy(0, 1);
  }
}
