import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "modalContent", "submit"];

  connect() {
    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = false;
  }

  showModalIfNotVisible(event) {
    if (this.modalTarget.hidden) {
      event.preventDefault()

      this.modalTarget.hidden = false;
      this.modalContentTarget.hidden = false;
      this.submitTarget.disabled = true;
    }
  }

  hideModalIfOutside(event) {
    if (this.modalTarget.contains(event.target) && !this.modalContent.contains(event.target)) {
      this.modalTarget.hidden = true;
      this.modalContentTarget.hidden = true;
      this.submitTarget.disabled = false;
    }
  }

  cancel(event) {
    event.preventDefault();

    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = false;
  }

  confirm(event) {
    event.preventDefault();

    this.element.requestSubmit();

    this.modalTarget.hidden = true;
    this.modalContentTarget.hidden = true;
    this.submitTarget.disabled = true;
  }
}
