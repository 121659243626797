import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["required", "pattern", "email"];

  connect() {
    $(this._simpleInputs()).on("blur", this.simpleBlur.bind(this));
    $(this._simpleInputs()).on("workelo:test", this.simpleTest.bind(this));
    $(this._simpleInputs()).on("invalid", this.simpleInvalid.bind(this));
    $(this._selectInputs()).on("workelo:blur", this.selectBlur.bind(this));
    $(this._selectInputs()).on("invalid", this.selectInvalid.bind(this));
    $(this.element).on("submit", this.submit.bind(this));
  }

  simpleBlur(event) {
    const input = event.target;
    const parent = input.parentElement;
    const value = (input.value || "").toString();
    const required = input.required;
    const pattern = (input.pattern || "").toString();
    const email = input.type === "email";
    const errors = parent.querySelectorAll(".js-input-error");

    errors.forEach((element) => element.remove());

    input.classList.add("input--dirty");

    if (required && !value) {
      parent.insertAdjacentHTML("beforeend", this.requiredTarget.innerHTML);
      return;
    }

    if (pattern && !new RegExp(`^${pattern}$`).test(value)) {
      parent.insertAdjacentHTML("beforeend", this.patternTarget.innerHTML);
      return;
    }

    if (email && $(input).is(":invalid")) {
      parent.insertAdjacentHTML("beforeend", this.emailTarget.innerHTML);
      return;
    }
  }

  simpleTest(event) {
    const input = event.target;
    const parent = input.parentElement;
    const errors = parent.querySelectorAll(".js-input-error");
    errors.forEach((element) => element.remove());
    input.classList.remove("input--dirty");
  }

  simpleInvalid(event) {
    event.preventDefault();

    this.simpleBlur(event);

    this._submits().forEach((submit) => (submit.disabled = false));
  }

  selectBlur(event) {
    const input = event.target.closest("input");
    const parent = input.parentElement.parentElement;
    const value = (input.value || "").toString();
    const required = input.required;
    const errors = parent.querySelectorAll(".js-input-error");

    errors.forEach((element) => element.remove());

    input.classList.add("input--dirty");

    if (required && !value) {
      parent.insertAdjacentHTML("beforeend", this.requiredTarget.innerHTML);
      return;
    }
  }

  selectInvalid(event) {
    event.preventDefault();

    this.selectBlur(event);

    this._submits().forEach((submit) => (submit.disabled = false));
  }

  submit() {
    this._submits().forEach((submit) => (submit.disabled = true));
  }

  _simpleInputs() {
    return this.element.querySelectorAll(
      "input:not(.input--select__text-input)",
    );
  }

  _selectInputs() {
    return this.element.querySelectorAll("input.input--select__text-input");
  }

  _submits() {
    return this.element.querySelectorAll("input[type=submit]");
  }
}
