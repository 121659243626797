import { Controller } from "@hotwired/stimulus";
// Documentation : https://github.com/jackocnr/intl-tel-input
import intlTelInput from 'intl-tel-input';

// HTML EXAMPLE :
//  <div data-controller="input-validations utils--phone-number-verifier"
//       data-utils--phone-number-verifier-target-selector-value="#phone_number_input"
//       data-utils--phone-number-verifier-valid-message-value="Format du numero international valide ✅"
//       data-utils--phone-number-verifier-invalid-message-value="Format du numero international invalide ❌">
//      <input
//        style="padding-left: 50px"
//        data-input-validations-target="input"
//        data-action="input->utils--phone-number-verifier#verify"
//        data-utils--phone-number-verifier-target="input"
//        name="phone_number"
//      />
//  </div>
//  <template id='error-presence'>
//    <span class="help-block"><%= t('errors.messages.blank') %></span>
//  </template>
//  <template id='error-format'>
//    <span class="help-block"><%= t('errors.messages.invalid') %></span>
//  </template>


// The element of this controller is the input wrapper
export default class extends Controller {
  static targets = ['input']

  connect() {
    this.phoneInput = intlTelInput(this.inputTarget, {
      preferredCountries: ["fr", "us", "be", "gb", "de", "pl", "ma"],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.3.3/js/utils.min.js"
    });
    this._setValue();
    this._changeValue();
  }

  verify() {
    let valuePresence = this._checkPresence();
    let isValid = this.phoneInput.isValidNumber();

    if (valuePresence && (isValid || !this.inputTarget.value.trim())) {
      this._validateInput();
    } else {
      this._invalidateInput(valuePresence);
    }
  }

  _checkPresence() {
    if (this.inputTarget.required) {
      // the value is required : cannot be blank
      return (this.inputTarget.value.trim() != '')
    } else {
      // the value is not required : anyway
      return true
    }
  }

  _validateInput() {
    this.inputTarget.dataset.valid = true;
    this.element.classList.remove('has-error');
    this._removeErrorMessage();
    this._changeValue();
  }

  _invalidateInput(valueIsPresent) {
    this.inputTarget.dataset.valid = false;
    this.element.classList.add('has-error');
    this._addErrorMessage(valueIsPresent);
  }

  _addErrorMessage(valueIsPresent) {
    this._removeErrorMessage();
    let errorMessageId = this._defineErrorMessageId(valueIsPresent);
    let messageContent = document.getElementById(errorMessageId).innerHTML;
    this.element.insertAdjacentHTML('beforeend', messageContent);
  }

  _defineErrorMessageId(valueIsPresent) {
    if (valueIsPresent) { return 'error-format'; } else { return 'error-presence'; }
  }

  _removeErrorMessage() {
    let message = this.element.querySelector('span.help-block');
    if (message) { message.remove(); }
  }

  _setValue() {
    let inputValue = this.inputTarget.value;
    if (inputValue) {
      this.phoneInput.promise.then(() => { this.phoneInput.setNumber(inputValue) })
    }
  }

  _changeValue() {
    this.phoneInput.promise.then(() => { this.inputTarget.value = this.phoneInput.getNumber() })
  }
}
