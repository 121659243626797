import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['collectionValues', 'valueInput']
  static values = {
    accountId: String
  };

  updateResourceType(event) {
    this.resourceType = event.currentTarget.value;
    const index = event.currentTarget.dataset.index;
    [...document.querySelectorAll(`.field-${index}`)].forEach(field => {
      const input = field.querySelector('input') || field.querySelector('select');
      input.name = '';
      field.classList.add('d-none')
    });
    const field = this.element.querySelector(`#${this.resourceType}-field`);
    const input = field.querySelector('input') || field.querySelector('select');
    input.name = `template_rule[template_criteria_attributes][${index}][field]`;
    field.classList.remove('d-none');
    this.element.querySelector(`#validation-type`).classList.remove('d-none');
  }

  updateValidationType(event) {
    this.validationType = event.currentTarget.value;
    if (this.resourceType) { this.element.querySelector('#add-value').classList.remove('d-none') }
  }

  async loadSelectTemplate(event) {
    const base_url = `/internal_api/v1/accounts/${this.accountIdValue}/${event.srcElement.dataset.endpoint}`;
    let params = ''
    if (event.srcElement.dataset.endpoint == 'unit_organizations') {
      params += `level_organization_name=${event.srcElement.value}`;
    } else {
      params += `identification_resource_id=${event.srcElement.value}&resource=IdentificationResource`;
    }

    await fetch(`${base_url}?${params}`)
    .then(response => response.json())
    .then(data => {
      let select = document.createElement('select')
      data.forEach(item => {
        const option = document.createElement('option');
        option.value = item.id;
        option.innerHTML = item.name || item.value;
        select.appendChild(option);
      });
      this.options = select.innerHTML;
    }).catch(error => {
      console.log(error);
    })
  }

  addValue(event) {
    const id = event.currentTarget.dataset.id;
    let isRow = this.resourceType == 'Row';
    const element = isRow ? this._buildHtmlAs('input', event, id) : this._buildHtmlAs('select', event, id);
    this.collectionValuesTarget.appendChild(element);
  }

  // duplicateCriteria() {
  //   const html = this.element.outerHTML;
  //   const newElement = html.replace(/template_rule\[template_criteria_attributes\]\[\d+\]/g, `template_rule[template_criteria_attributes][${new Date().getTime()}]`)
  //   this.element.insertAdjacentHTML('afterend', newElement)
  // }

  destroyValue(_event) {
    this.element.remove();
  }

  resetValues() {
    this.collectionValuesTarget.innerHTML = '';
  }

  _buildHtmlAs(type, event, id) {
    let html = event.currentTarget.dataset[`${type}Fields`];
    html = html.replaceAll(id, new Date().getTime());
    const htmlElement = new DOMParser().parseFromString(html, "text/html");
    const wrapperElement = htmlElement.querySelector('body').firstChild;
    wrapperElement.firstElementChild.style.width = '100%';
    if (type == 'select') {
      const selectElement = wrapperElement.firstElementChild;
      selectElement.innerHTML = this.options;
      if (this.resourceType == 'Identification') {
        $(selectElement).selectize({
          onInitialize: () => { wrapperElement.querySelector('[name*="parent_id"]').value = selectElement.value },
          onChange: value => { wrapperElement.querySelector('[name*="parent_id"]').value = value }
        });
      } else {
        $(selectElement).selectize();
      }
      wrapperElement.querySelector('.selectize-control').style.width = '100%';
    }
    return wrapperElement;
  }
}
