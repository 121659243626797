import { Controller } from "@hotwired/stimulus"

export default class BaseReportsController extends Controller {
  static targets = ['block', 'dateInput']

  connect() {
    if (!this.element()) { return };
    this.loadReports();
  }

  loadReports() {
    const date = this.element().dataset.date;
    this.blockTargets.forEach(block => {
      fetch(`${block.dataset.url}?date=${date}`).then(response => {
        return response.ok ? response.json() : console.log('Can\'t load report table');
      })
      .then(JSONResponse => {
        document.getElementById(`${JSONResponse.account_id}-report-content`).innerHTML = JSONResponse.content;
      })
      .catch(error => {
        console.log(error);
      });
    });
  }

  changeDate() {
    this.updateParams();
    this.updateDateLabel();
    this.displaySpinners();
    this.loadReports();
  }

  updateParams() {
    this.element().dataset.date = this.dateInputTarget.value.split('-').join('');
    setTimeout(() => {
      this.dateInputTarget.value = '';
    }, 3000);
  }

  updateDateLabel() {
    const dateMatch = this.element().dataset.date.match(/(\d{4})(\d{2})(\d{2})/);
    document.getElementById('date-label').innerText = `${dateMatch[3]}/${dateMatch[2]}/${dateMatch[1]}`;
  }

  displaySpinners() {
    this.blockTargets.forEach(block => {
      const account_id = block.id.split('-')[0];
      const spinner = '<i class="fad fa-spinner text-gray rotating font-size-3xl" aria-hidden="true"></i>';
      document.getElementById(`${account_id}-report-content`).innerHTML = spinner;
    });
  }
}
