import Swal from "sweetalert2";

export function allowToRemoveTemporalityBlock() {
  addDeleteWeekButtons();
  const minuses = document.querySelectorAll('.remove-temporality-block');
  minuses.forEach(minus => {
    minus.addEventListener('click', event => {
      const weekBlock = event.target.parentElement.parentElement.parentElement;
      const blockHasElements = weekBlock.querySelector('.line-action');
      if (blockHasElements) {
        // warning before removing everything
        displaySweetAlertWarning(weekBlock);
      } else {
        // remove empty block
        weekBlock.remove();
        createInvisibleAddWeekButton();
      }
    });
  });
}

// we add the minus dynamically with js instead of loading them in the html because
// it allows us to display the new ones easier when we add a new week
function addDeleteWeekButtons() {
  // first, we clean all the previous for the case we are adding a new one by setting up a new week
  [...document.querySelectorAll('.remove-temporality-block')].forEach(el => el.remove());

  const weeks = [...document.querySelectorAll('.week')];
  weeks.forEach(week => {
    // we don't add a remove button is the week contains
    // 1. an undraggable div, to prevent from removing a MoT element
    // 2. the week dosen't contains day 0 or day -7 as we want them to be always present
    // 3. the first connection as we also want it to be always present
    const canAddDeleteButton = !week.querySelector('.undraggable-day') && !week.innerHTML.includes('day_-7') && !week.innerHTML.includes('day_0') && !week.innerHTML.includes('day_first-connection');
    if (canAddDeleteButton) {
      const minus = `
        <div class="remove-temporality-block margin-right-5px margin-top-5px">
          <i style="height: 100%" class="fa fa-minus-circle text-gray pointer" aria-hidden="true"></i>
        </div>
      `;
      week.querySelector('.block-title').insertAdjacentHTML('beforeend', minus);
    }
  })
}

function displaySweetAlertWarning(event) {
  const datas = document.getElementById('setting-content');
  Swal.fire({
    title: datas.dataset.removeBlockTitle,
    text: datas.dataset.removeBlockText,
    icon: "warning",
    confirmButtonText: datas.dataset.removeBlockYes,
    cancelButtonText: datas.dataset.removeBlockNo,
    showCancelButton: true,
    showCloseButton: true,
    closeButtonHtml: '<i class="fas fa-times-circle font-size-md"></i>',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'button button-red m-2',
      cancelButton: 'button button-white m-2'
    }
  }).then((result) => { if (result.isConfirmed) removeElements(event)});
};

function removeElements(weekBlock) {
  const weekId = weekBlock.dataset.week
  const elements = [...weekBlock.querySelectorAll('.line-action')];
  const elementsToDestroy = [];
  elements.forEach(element => {
    const data = {
      model: element.id.split('_')[0],
      id: element.id.split('_').pop()
    }
    elementsToDestroy.push(data);
  });
  const templateId = document.getElementById('setting-content').dataset.templateId;
  $.ajax({
    url: `/settings/templates/${templateId}/draggable_elements/${templateId} `,
    type: 'DELETE',
    data: { week: weekId, elements_to_destroy: elementsToDestroy },
    dataType: 'script'
  });
}
